import { AnalyticsBrowser } from '@segment/analytics-next';
import { FC, ReactNode, useMemo, createContext } from 'react';

export const AnalyticsContext = createContext<AnalyticsBrowser>(undefined!);

type AnalyticsProviderProps = {
  writeKey: string;
  children: ReactNode;
};
export const AnalyticsProvider: FC<AnalyticsProviderProps> = ({
  children,
  writeKey,
}) => {
  const analytics = useMemo(
    () => AnalyticsBrowser.load({ writeKey }),
    [writeKey]
  );

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};
