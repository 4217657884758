// React and third-party libraries
import React, { useEffect } from 'react';
import type { AppProps } from 'next/app';
import cookie from 'js-cookie';

//Providers
import AnalyticsProvider from 'lib/analytics';
import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import useDialogue, {
  DialogueContext,
} from 'customHooks/useDialogue/useDialogue';
import useUser, { UserContext } from 'customHooks/useUser/useUser';
import useJoinUs, { JoinUsContext } from 'customHooks/useJoinUs/useJoinUs';

//Components
import SessionService from 'services/SessionService/SessionService';

import {
  NEWSLETTER_SUBSCRIPTION_MODAL,
  COOKIE_CONSENT_MODAL,
  sessionStorageKeys,
} from 'data/constants';

//styles
import muiTheme from 'styles/MUITheme';
import 'styles/main.scss';
import 'styles/global.css';
import 'styles/fonts.css';

//utils
import { startBugsnag } from 'utils/bugsnag';
import createEmotionCache from 'utils/createEmotionCache';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import useDefaultCurrency from 'customHooks/useDefaultCurrency';
import { IGlobalLayout } from 'types/interfaces';
import useSignIn, { SignInContext } from 'customHooks/useSignIn/useSignIn';

startBugsnag();

// Emotion~MaterialUI Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

export interface LEAppProps extends AppProps {
  emotionCache?: EmotionCache;
  pageProps: AppProps['pageProps'] & {
    globalLayoutProps?: IGlobalLayout;
  };
}

function MyApp({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
}: LEAppProps) {
  const {
    joinUsState,
    setState: setStateInJoinUsContext,
    handleTextChange: handleTextChangeInJoinUsContext,
    handleCheckBoxChange: handleCheckBoxChangeInJoinUsContext,
    handleSelectChange,
    handleCreditCardChange,
    handleCityChange,
    handleResetForm,
  } = useJoinUs();

  const {
    signInState,
    setState: setStateInSignInContext,
    handleTextChange: handleTextChangeInSignInContext,
    handleCheckBoxChange: handleCheckBoxChangeInSignInContext,
  } = useSignIn();

  const { dialogueState, openDialogue, closeDialogue } = useDialogue({
    onDialogueClose: handleResetForm,
  });

  const { state: userState, setUser } = useUser();

  useEffect(() => {
    if (
      SessionService.getItemFromSession(
        sessionStorageKeys.NEWSLETTER_MODAL_OPENED
      ) === 'true'
    ) {
      return;
    }

    const consentCookie = cookie.get('cookieConsent');

    if (!consentCookie) {
      SessionService.setItemToSession(
        sessionStorageKeys.COOKIE_CONSENT_MODAL_OPENED,
        'true'
      );

      openDialogue(COOKIE_CONSENT_MODAL);
    } else {
      openDialogue(NEWSLETTER_SUBSCRIPTION_MODAL);
      SessionService.setItemToSession(
        sessionStorageKeys.NEWSLETTER_MODAL_OPENED,
        'true'
      );
    }
  }, [openDialogue]);

  const currencies = pageProps?.globalLayoutProps?.currencies;

  useDefaultCurrency({
    setState: setStateInJoinUsContext,
    currencies: currencies ?? [],
    shouldFetch: !!currencies && !joinUsState.currency,
  });

  const queryClient = new QueryClient();

  return (
    <AnalyticsProvider
      writeKey={process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY as string}
    >
      <QueryClientProvider client={queryClient}>
        <CacheProvider value={emotionCache}>
          <ThemeProvider theme={muiTheme}>
            <ReactQueryDevtools />

            <LocalizationProvider dateAdapter={AdapterMoment}>
              <UserContext.Provider
                value={{
                  state: userState,
                  setUser,
                }}
              >
                <DialogueContext.Provider
                  value={{
                    dialogueState,
                    openDialogue,
                    closeDialogue,
                  }}
                >
                  <JoinUsContext.Provider
                    value={{
                      joinUsState,
                      setState: setStateInJoinUsContext,
                      handleTextChange: handleTextChangeInJoinUsContext,
                      handleCheckBoxChange: handleCheckBoxChangeInJoinUsContext,
                      handleSelectChange,
                      handleCreditCardChange,
                      handleCityChange,
                      handleResetForm,
                    }}
                  >
                    <SignInContext.Provider
                      value={{
                        signInState,
                        setState: setStateInSignInContext,
                        handleTextChange: handleTextChangeInSignInContext,
                        handleCheckBoxChange:
                          handleCheckBoxChangeInSignInContext,
                      }}
                    >
                      <Component {...pageProps} />
                    </SignInContext.Provider>
                  </JoinUsContext.Provider>
                </DialogueContext.Provider>
              </UserContext.Provider>
            </LocalizationProvider>
          </ThemeProvider>
        </CacheProvider>
      </QueryClientProvider>
    </AnalyticsProvider>
  );
}

export default MyApp;
