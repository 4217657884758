import { EventProperties } from '@segment/analytics-next';
import { useRouter } from 'next/router';
import React, { useContext, useEffect } from 'react';
import { AnalyticsContext } from './context';

const useAnalytics = () => {
  const context = useContext(AnalyticsContext);
  if (context === undefined) {
    throw new Error('Context used outside of its Provider!');
  }

  return context;
};

type TrackOnMountProps = {
  eventName?: string;
} & EventProperties;

// track event on component mount, the default event name is the current route
// as shown in the browser including the search params
const useTrackOnMount = ({ eventName, ...properties }: TrackOnMountProps) => {
  const analytics = useAnalytics();

  const router = useRouter();
  const eventNameToTrack = eventName || router.asPath;

  useEffect(() => {
    analytics.track(eventNameToTrack || router.asPath, properties);
  }, [analytics, eventNameToTrack, properties, router.asPath]);
};

export { useAnalytics, useTrackOnMount };
